import React from 'react'
import { Box, Button, Flex, Image, Link, Text } from 'theme-ui'
import Star from '../Star'
import getImageVariant from '@components/utils/getImageVariant'


const CardPostInfo = ({ post, small }) => {

  const logo = getImageVariant(post.logo, 'logo')

  return (
    <Flex sx={{
      alignItems: 'center',
      justifyContent: 'space-between',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: small ? '.65rem' : '.75rem',
      flexWrap: 'wrap',
      gap: '0px 15px',
      '@media screen and (max-width: 700px)': {
        flexDirection: 'column',
      }
    }}>
      <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
        {logo && <Image sx={{ maxWidth: '112px' }} src={logo.images.fallback.src} />}
        {post.score && <Box sx={{ fontSize: small ? '16px' : '24px' }}><Star rating={post.score} /></Box>}
      </Flex>
      <Flex sx={{
        flexDirection: 'column',
        '@media screen and (max-width: 700px)': {
          alignItems: 'center',
        }
      }}>
        {post.website && <Flex>
          <Text sx={{ marginRight: '6px' }}>Website: </Text>
          <Link target="_blank" href={post.website}>Link</Link>
        </Flex>}
        {(post.countryExchange || post.countryWallet) && <Flex>
          <Text sx={{ marginRight: '6px' }}>Country: </Text> {post.countryExchange || post.countryWallet}
        </Flex>}
      </Flex>
      <Flex sx={{
        flexDirection: 'column',
        paddingLeft: '30px',
        borderLeft: '2px solid #667eea',
        '@media screen and (max-width: 700px)': {
          borderLeft: 'none',
          alignItems: 'center',
          paddingLeft: '0px'
        }
      }}>
        {post.transactionFees && <Flex>
          <Text sx={{ marginRight: '6px' }}>Transaction Fees: </Text> {post.transactionFees}
        </Flex>}
        {post.currenciesAvailable && <Flex sx={{alignItems: 'center'}}>
          <Text sx={{ marginRight: '6px' }}>Currencies Available: </Text> <Box sx={{maxWidth: '60px'}}>{post.currenciesAvailable}</Box>
        </Flex>}
      </Flex>
      {(post.ourReview || post.referralLink) && <Flex sx={{
        flexDirection: 'column', fontSize: '12px',
        '@media screen and (max-width: 700px)': {
          marginTop: '15px'
        }
      }}>
        {post.ourReview &&
        <Link target="_blank" href={post.ourReview.slug}><Button sx={{ mb: '1' }}>Read Our Review</Button></Link>}
        {post.referralLink && <Link target="_blank" href={post.referralLink}><Button sx={{
          background: 'green',
          borderColor: 'green',
          width: '100%',
          minWidth: small ? '9rem' : '10rem',

        }}>Get Bonus Credit</Button></Link>}
      </Flex>}
    </Flex>
  )
}

export default CardPostInfo
