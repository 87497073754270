import React from 'react'
import { Card as CardComponent } from 'theme-ui'
import { Layout, Stack, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Sticky from '@components/Sticky'
import Seo from '@widgets/Seo'
import AuthorCompact from '@widgets/AuthorCompact'
import {
  PostHead,
  PostImage,
  PostBody,
  PostComments,
  PostTagsShare,
  PostFooter
} from '@widgets/Post'
import CardPostInfo from '@elegantstack/flow-ui-components/src/Card/Card.Post.Info'
import ProsAndCons from '@elegantstack/flow-ui-components/src/WalletReview/ProsAndCons'

const Review = ({ data: { review, reviews, previous, next }, ...props }) => {
  const { pageContext: { services = {}, siteUrl } = {} } = props

  return (
    <Layout {...props}>
      <Seo {...review} siteUrl={siteUrl} isReview={true} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PostHead {...review} />
      </Stack>
      <Divider />
      <Stack effectProps={{ fraction: 0 }}>
        <Main>
          <CardComponent variant='paper'>
            <PostImage {...review} inCard />
          </CardComponent>
          {(review.website ||
            review.transactionFees ||
            review.currenciesAvailable) && (
            <CardComponent sx={{ mt: '3', p: '4' }}>
              <CardPostInfo small post={review} />
            </CardComponent>
          )}
          <Divider space={2} />
          <CardComponent variant='paper'>
            <ProsAndCons {...review} />
          </CardComponent>
          <Divider space={2} />
          <CardComponent variant='paper'>
            {review.body && <PostBody {...review} />}
            <PostTagsShare {...review} location={props.location} />
            {services.disqus && <PostComments {...review} />}
            <PostFooter {...{ previous, next }} />
          </CardComponent>
        </Main>
        <Sidebar>
          <AuthorCompact author={review.author} omitTitle />
          <Divider />
          {reviews.nodes && (
            <Sticky>
              <CardList
                title='Other Reviews'
                nodes={reviews.nodes}
                variant='horizontal-aside'
                limit={4}
                omitMedia
                omitCategory
                distinct
                aside
              />
            </Sticky>
          )}
        </Sidebar>
      </Stack>
    </Layout>
  )
}

export default Review
