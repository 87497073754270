import { graphql } from 'gatsby'
import ReviewPage from '../containers/Review'

export default ReviewPage

export const pageQuery = graphql`
  query ReviewPageQuery(
    $id: String!
    $previousId: String
    $nextId: String
    $categoryId: String
  ) {
    review: review(id: { eq: $id }) {
      ...ReviewInformation
      ...ReviewThumbnailRegular
      ...ReviewLogo
    }

    reviews: allReview(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...ReviewPreview
        ...ReviewThumbnailRegular
        ...ReviewLogo
      }
    }

#    # Related walletReviews based on tags and category
#    tagCategoryWalletReviews: allWalletReview(
#      filter: {
#        private: { ne: true }
#        draft: {ne: true}
#        category: { id: { eq: $categoryId } }
#        id: { ne: $id }
#      }
#      sort: { fields: [date], order: DESC }
#      limit: 6
#    ) @include(if: $hasTags) {
#      nodes {
#        ...WalletReviewPreview
#        ...WalletReviewThumbnailRegular
#        ...WalletReviewLogo
#      }
#    }

    # Related walletReviews based on tags only
#    tagWalletReviews: allWalletReview(
#      filter: {
#        private: { ne: true }
#        draft: {ne: true}
#        id: { ne: $id }
#      }
#      sort: { fields: [date], order: DESC }
#      limit: 6
#    ) @include(if: $hasTags) {
#      nodes {
#        ...WalletReviewPreview
#        ...WalletReviewThumbnailRegular
#        ...WalletReviewLogo
#      }
#    }

    # Related walletReviews based on category only
    categoryReviews: allReview(
      filter: {
        private: { ne: true }
        draft: {ne: true}
        category: { id: { eq: $categoryId } }
        id: { ne: $id }
      }
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        ...ReviewPreview
        ...ReviewThumbnailRegular
        ...ReviewLogo
      }
    }

    previous: review(id: { eq: $previousId }) {
      id
      slug
      title
    }

    next: review(id: { eq: $nextId }) {
      id
      slug
      title
    }
  }
`
