import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import components from '@components/Mdx'

export const PostBody = ({ body, isRank, title, rank }) => {
  return (
    <MDXProvider components={components}>
      {isRank && <h2>{rank}. {title}</h2>}
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  )
}
