/** @jsx jsx */
import { jsx } from 'theme-ui'
import React from 'react'
import { Box, Flex, Text } from 'theme-ui'

const styles = {
  heading: {
    fontSize: '32px',
    fontWeight: '900'
  },
  list: {
    paddingLeft: 0,
  }
}

const ProsAndCons = ({ pros, cons }) => {
  return (
    <Flex sx={{
      justifyContent: 'center',
      gap: '50px',
    }}>
      <Box>
        <Flex sx={{alignItems: 'center', marginRight: '5px'}}>
          <Text sx={{
            lineHeight: 'normal',
            fontSize: '21px',
            textAlign: 'center',
            borderRadius: '360px',
            border: '2px solid',
            width: '28px',
            height: '28px',
            marginRight: '10px'
          }}>✓</Text>
          <Text sx={styles.heading}> Pros</Text>
        </Flex>
        <ul sx={styles.list}>
          {pros && pros.map((item, idx) => <li key={idx}>{item}</li>)}
        </ul>
      </Box>
      <Box>
        <Flex sx={{alignItems: 'center', marginRight: '5px'}}>
          <Text sx={{
            lineHeight: 'normal',
            fontSize: '21px',
            textAlign: 'center',
            borderRadius: '360px',
            border: '2px solid',
            width: '28px',
            height: '28px',
            marginRight: '10px'
          }}>!</Text>
          <Text sx={styles.heading}> Cons</Text>
        </Flex>
        <ul sx={styles.list}>
          {cons && cons.map((item, idx) => <li key={idx}>{item}</li>)}
        </ul>
      </Box>
    </Flex>
  )
}

export default ProsAndCons
